import { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

const MatintaTasksNew: React.FC<{onCancel:any}> = ({onCancel}) => {
  const { register, handleSubmit, watch } = useForm();
  const [ _, setTask ] = useState({
    avatar: "https://upload.wikimedia.org/wikipedia/commons/4/45/Glider.svg",
    title: "Hack3d",
    description: "Hacked", 
    createdAt: "Hacked 3 hours ago",
    name: "wacko"
  })

  const onSubmit = (data:any) => {
    console.log(data);
  };

  watch((data:any) => {
    setTask(data)
  })

  return (
        <div className="row">
          <div className="col col-md-12">
            <div className="card p-4">
              <div className="row">
                <h3 className="report-answer fw-normal mb-4">Nova Atividade</h3>
              </div>
              <div className="row justify-content-end"></div>                    
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="title">Titulo</label>
                  <input id="title" type="title" className="form-control form-control-lg" {...register("title", {required: true})}/>
                </div>
                {/* <div className="form-group mt-4">
                  <label htmlFor="category">Categoria</label>
                  <select id="category" className="form-control" {...register("category", {required: true})}>
                    <option>Pessoal</option>
                    <option>Trabalho</option>
                  </select>
                </div> */}
                <div className="form-group mt-4">
                  <label htmlFor="description">Descrição da atividade</label>
                  <textarea id="description" className="form-control" rows={3} {...register("description", {required: true})}></textarea>
                </div>
                <div className="d-flex flex-row-reverse mt-4">
                  <Button type="submit">Salvar</Button>&nbsp;
                  <Button type="submit" onClick={onCancel}>Cancelar</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
  );
}

export default MatintaTasksNew;
