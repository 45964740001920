const TaskTabDescription = () => {
  return <div className="container-fluidx pt-4">
          <div className="row">
            <h3 className="report-answer fw-normal mb-4">Ferias Junho: Viagem para Europa</h3>
            <p>
              Olá, esse ano vou tirar ferias de 30 dias em Junho e interessado em viajar para Europa, a ideia é ir a familia toda, eu minha esposa e meus dois filhos pequenos, 
              nosso plano é ter uma rotina onde vamos sair cedo do local em que vamos nos hospedar e voltar durante o periodo da tarde. Como as crianças vão estar conosco não temos planos de fazer nada durante a noite.
            </p>

            <p>
              Meu plano é economizar o maximo em hospedagem mas ao mesmo tempo gostaria de ter otimas experiencias gastronomicas e conhecer lugares historicos, por favor monte uma sugestão de roteiro considerando essas coisas que
              acabei de descrever acima,
            </p>

            <p>obrigado, John Doe</p>
          </div>

          <div className="d-flex flex-column">
            <div className="d-flex flex-row-reverse">
                <div className="d-flex flex-column align-items-center ms-3">
                  <img src="https://api.dicebear.com/6.x/micah/svg?seed=Felix" style={{"width": "100px"}}/>    
                  <p className="mb-0 mt-1"><b>Felister Simpsom</b></p>
                  <small>Especialista</small>
                </div>

                <div className="d-flex flex-column align-items-center ms-3">
                  <img src="https://api.dicebear.com/6.x/micah/svg?seed=Zoe" style={{"width": "100px"}}/>    
                  <p className="mb-0 mt-1"><b>John Doe</b></p>
                  <small>Curador</small>
                </div>
              </div>
          </div>
        </div>
}

export default TaskTabDescription