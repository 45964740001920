import { Link } from "react-router-dom";
import MatintaJob from "../user/MatintaJob.model";

const MatintaTasksCardMini: React.FC<{job: MatintaJob}> = ({job}) => {
  return (
        <div className="card mb-3 p-2">
          <div className="row g-0 text-center">
            <div className="col-md-12">
              <img src={job.avatar} style={{"width": "250px", "height": "250px"}} className="p-4 img-fluid rounded-circle"/>
              <p><Link to="/tasks/1">{job.title}</Link></p>
              <p className="card-text mb-0">{job.kind} </p>
              <p><small className="text-body-secondary">{job.createdAt}<br/>{job.status}</small></p>
            </div>
          </div>
        </div>
  );
}

export default MatintaTasksCardMini;
