import MatintaJob from "../user/MatintaJob.model"

class MatintaTaskService {
    _jobs: Array<MatintaJob> = [
        {
          title: "Planejamento Viagem", 
          avatar: "https://media.gettyimages.com/id/1255493335/pt/foto/womans-legs-splashing-water-on-the-beach.jpg?s=2048x2048&w=gi&k=20&c=obbD00qcQoFertmwAb1yBhG6JkK8wbJFhWL2HUFHOv4=", 
          createdAt: "5 dias atras", 
          kind: "Familia",
          status: "Finalizado"
        },
        {
          title: "Informações emissão Passaporte", 
          avatar: "https://media.gettyimages.com/id/157502440/pt/foto/passaporte-eua-tra%C3%A7ado-de-recorte.jpg?s=2048x2048&w=gi&k=20&c=PxMeQS-BZjdhw15O6CfzGKM2hZPaXpO2ngFiJjq-EyY=", 
          createdAt: "5 dias atras", 
          kind: "Pessoal",
          status: "Finalizado"
        },
        {
          title: "Escola Infantil Guaruja", 
          avatar: "https://media.gettyimages.com/id/1150471641/pt/foto/paying-attention-a-class-at-elementary-school.jpg?s=2048x2048&w=gi&k=20&c=W4yO4C2taYMWaRMvU2Iu5ywozuDBgx_r14FpIU4j6nU=", 
          createdAt: "5 dias atras", 
          kind: "Pessoal",
          status: "Em andamento"
        },
        {
          title: "Natação Baixada Santista", 
          avatar: "https://media.gettyimages.com/id/dv1983003/pt/foto/young-woman-swimming-in-a-pool-underwater.jpg?s=2048x2048&w=gi&k=20&c=Rkp_4r_lvPvM2jRZrTx6BdOGijxowGM3ENtO5IC1qpc=", 
          createdAt: "5 dias atras", 
          kind: "Pessoal",
          status: "Em curadoria"
        },
        {
            title: "Natação Baixada Santista", 
            avatar: "https://media.gettyimages.com/id/dv1983003/pt/foto/young-woman-swimming-in-a-pool-underwater.jpg?s=2048x2048&w=gi&k=20&c=Rkp_4r_lvPvM2jRZrTx6BdOGijxowGM3ENtO5IC1qpc=", 
            createdAt: "5 dias atras", 
            kind: "Pessoal",
            status: "Em curadoria"
          },
    ]
    
    public list(): Array<MatintaJob> {
        return this._jobs;
    }

    public add(job: MatintaJob): void {
        this._jobs.push(job)
    }
}

export default new MatintaTaskService()