import { Button } from "react-bootstrap"

const TaskTabCommentsReply: React.FC<{cancelHandler:any, addCommentHandler:any}> = ({cancelHandler, addCommentHandler}) => {
  return (
    <div className="container-fluid p-4" style={{"backgroundColor": "#EFEFEF"}}>
      <div className="row mt-2">
        <div className="form-floating">
          <textarea className="form-control" id="floatingTextarea" style={{"height": "100px"}}></textarea>
        </div>
      </div>
      <div className="d-flex flex-row-reverse mt-4">
        <Button size="sm" onClick={() => { addCommentHandler("Hacked!"); cancelHandler()}}>Responder</Button>&nbsp;
        <Button variant="secondary" size="sm" onClick={cancelHandler}>Cancelar</Button>
      </div>
    </div>
  )
}

export default TaskTabCommentsReply