function MatintaLanding() {
    return (
<main>
  <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img width="100%" src="https://media.gettyimages.com/id/1220570381/pt/foto/smart-tech-helps-him-make-smarter-business-moves.jpg?s=2048x2048&w=gi&k=20&c=YGhA1Thf1dkcn8cWLyinC38VHFYip_vy05aqX3ShXD8="/>
        <div className="container">
          <div className="carousel-caption text-start">
            <h1 style={{"display": "inline-block", "fontSize": "100px", "color": "#6B696A"}}>Matinta</h1><br/>
            <p style={{"color": "#6B696A", "backgroundColor": "rgba(51, 170, 51, .3)", "display": "inline-block"}}>Bem vindo a sua assintente Pessoal</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="container marketing">
    <div className="row featurette">
      <div className="col-md-7">
        <h2 className="featurette-heading fw-normal lh-1">Vai viajar mas ta sem tempo pra nada! <span className="text-body-secondary" style={{"color": "#6B696A;"}}>Gostaria de um roteiro?</span></h2>
        <p className="lead">Matinta conhece o mundo inteiro e tem otimas dicas pra te dar! Diz pra gente pretende fazer, nós vamos te ajudar!</p>
      </div>
      <div className="col-md-5">
        <img className="rounded" width="100%" src="https://media.gettyimages.com/id/1201669316/pt/foto/happy-girl-climber-on-break.jpg?s=2048x2048&w=gi&k=20&c=5XKpOoqf6j_px2PXleJp4qLt_L_0ElwaUhUxCz5GLks="/>
      </div>
    </div>

    <hr className="featurette-divider"/>

    <div className="row featurette">
      <div className="col-md-7 order-md-2">
        <h2 className="featurette-heading fw-normal lh-1">Ta pensando em trocar de carro? <span className="text-body-secondary" style={{"color": "#6B696A;"}}>Que trabalheira.</span></h2>
        <p className="lead">Já tem modelo em mente? Me diz o que você deseja, eu vou montrar um relatorio com melhor custo e kilometragem</p>
      </div>
      <div className="col-md-5 order-md-1">
        <img className="rounded" width="100%" src="https://media.gettyimages.com/id/1216835172/pt/foto/happy-family-choosing-a-new-car-in-a-showroom.jpg?s=2048x2048&w=gi&k=20&c=OSCRP5M9B6ooIyUlwbdheTlStw48bCWcDWObxITiQd0="/>
      </div>
    </div>

    <hr className="featurette-divider"/>

    <div className="row featurette">
      <div className="col-md-7">
        <h2 className="featurette-heading fw-normal lh-1">Ta sem o que fazer hoje a noite? <span className="text-body-secondary" style={{"color": "#6B696A;"}}>Bora sair pra jantar?</span></h2>
        <p className="lead">Estamos sempre por dentro de tudo de novo que ta acontecendo na cidade, restaurantes, bares, show e eventos, me diz ai, bora dar uma olhada em algo pra fazer hoje?</p>
      </div>
      <div className="col-md-5">
        <img className="rounded" width="100%" src="https://media.gettyimages.com/id/1470433739/pt/foto/man-feeding-snack-to-woman-at-dining-table.jpg?s=2048x2048&w=gi&k=20&c=NGD_ssz7iPYOirR-5EueY3-7Liow9nVNVO13raxDuYU="/>
      </div>
    </div>
  </div>
</main>
    );
}

export default MatintaLanding;
