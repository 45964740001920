import { useState } from "react";
import { Button } from "react-bootstrap";

import MatintaTasksCardMini from "./MatintaTasksCardMini.component";
import MatintaTasksNew from "./MatintaTasksNew.component";
import MatintaTaskService from "./matinta.service";

function MatintaTasks() {
  const [renderNew, setRenderNew] = useState(false)
  const [tasks, setTasks] = useState(MatintaTaskService.list())

  return (
    <main>
      <div className="container">
        { renderNew || <div className="d-flex flex-row-reverse mt-4">
                        <Button className="justify-content-end" onClick={() => setRenderNew(true)}>Novo</Button>
                      </div> }
        { renderNew && <div className="row mt-4">
                          <MatintaTasksNew onCancel={() => setRenderNew(false)}/>
                        </div> }
        <div className="row mt-4">
          {tasks.map((task) => (
            <div className="col col-md-3">
              <MatintaTasksCardMini job={task}/>
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}

export default MatintaTasks;
