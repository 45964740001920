import { Outlet } from "react-router-dom";
import MatintaPageHeader from "./MatintaPageHeader.component";

function MatintaDefaultLayout() {
    return (
        <>
            <MatintaPageHeader/>
            <Outlet/>
        </>
    )
}

export default MatintaDefaultLayout;