import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import MatintaDefaultLayout from './general/MatintaDefaultLayout';
import MatintaLanding from './general/MatintaLanding';
import MatintaUserLogin from './user/MatintaUserLogin.component';
import MatintaTasks from './tasks/MatintaTasks';
import MatintaTasksShow from './tasks/MatintaTasksShow.component';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MatintaDefaultLayout />}>
          <Route index element={<MatintaLanding />} />
          <Route path='/user/login' element={<MatintaUserLogin />} />
          <Route path='/tasks' element={<MatintaTasks />} />
          <Route path='/tasks/1' element={<MatintaTasksShow />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
