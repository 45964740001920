import { useState } from "react"
import classNames from "classnames";
import TaskTabDescription from "./show/TaskTabDescription.component";
import TaskTabExecution from "./show/TaskTabExecution.component";
import TaskTabComments from "./show/TaskTabComments.component";
import { Badge } from "react-bootstrap";

const registeredTabs:any = {
  "Descrição": () => <TaskTabDescription/>,
  "Atividade": () => <TaskTabExecution/>,
  "Mensagens": () => <TaskTabComments/>
}

const TabLink:React.FC<{name: string, currentTab: string, setTabs:any}> = ({name, currentTab, setTabs}) => {
  return <a className={classNames({"nav-link": true, "active": currentTab == name})} onClick={() => setTabs(name)}>
            {name} <Badge>2</Badge>
         </a>
}

function renderTab(name:string) {
  return registeredTabs[name]();
}

function MatintaTasksShow() {
  const [tabs, setTabs] = useState("Descrição"); 

  return (
    <main>
      <div className="container">
        <div className="row m-4">
          <div className="col col-md-12">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <TabLink name="Descrição" currentTab={tabs} setTabs={setTabs}></TabLink>
              </li>              
              <li className="nav-item">
                <TabLink name="Atividade" currentTab={tabs} setTabs={setTabs}></TabLink>
              </li>
              <li className="nav-item">
                <TabLink name="Mensagens" currentTab={tabs} setTabs={setTabs}></TabLink>
              </li>
            </ul>
            <div>{renderTab(tabs)}</div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default MatintaTasksShow