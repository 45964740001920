import { useState } from "react";
import { Button } from "react-bootstrap";
import TaskTabCommentsReply from "./TaskTabCommentsReply.component";

const pickBackground = (seed:number) => {
  const color = seed % 2 === 0 ? "#EFEFEF" : "#FFFFF"
  return {"backgroundColor": color}
}

const internalComments = [
  {
    avatar: "https://api.dicebear.com/6.x/adventurer/svg?seed=Zoe&flip=true",
    name: "John Doe",
    role: "Curador",
    createdAt: "3 horas atras",
    message: "Olá André, gostaria de entender um pouco melhor a atividade, dei uma reescrita na descrição, faz sentido? poderia confirmar se está ok?",
    check: true,
    reply: false
  },
  {
    avatar: "https://api.dicebear.com/6.x/adventurer/svg?seed=Felix&flip=true",
    name: "Felister Simpsom",
    role: "Especialista",
    createdAt: "A 30 minutos",
    message: "Pessoal ja estou trabalhando na atividade, qualquer novidade me chama!",
    check: false,
    reply: true
  }
];

const TaskTabComments = () => {
  const [renderReply, setRenderReply] = useState(false)
  const [comments, setComments] = useState(internalComments)

  const newComment = (comment:string) => {
    setComments([...comments, {
      avatar: "https://api.dicebear.com/6.x/adventurer/svg?seed=Andrews",
      name: "Andre Souza",
      role: "Me",
      createdAt: "0 minutos",
      message: comment,
      check: false,
      reply: false
    }])
  }

  return (
      <div className="container-fluid">
        <div className="row mt-4">
          <h3 className="report-answer fw-normal mb-4">Comentarios</h3>
        </div>

        {comments.map((comment, index) => (
          <div className="d-flex flex-row p-2 pb-0" style={pickBackground(index)}>
            <img src={comment.avatar} width="80" className="rounded-circle m-4"/>
            <div className="w-100">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-row align-items-center">
                  <h6 className="card-title">{comment.name}</h6>
                  &nbsp;&nbsp;
                  <small className="ml-2">{comment.role}</small>
                </div>
                <small>{comment.createdAt}</small>
              </div>
              <p className="text-justify comment-text mt-2">{comment.message}</p>
              <div className="d-flex flex-row-reverse">
                {comment.check ? <Button variant="success" disabled>Ok</Button> : <></>}
                {comment.reply && !renderReply ? <Button onClick={() => setRenderReply(true)}>Reply</Button> : <></>}
                {comment.reply && renderReply ? <Button variant="secondary" onClick={() => setRenderReply(false)}>Cancel</Button> : <></>}
              </div>
            </div>
          </div>
        ))}

        {renderReply ? <TaskTabCommentsReply cancelHandler={() => setRenderReply(false)} addCommentHandler={(comment:string) => newComment(comment)}/> : <></>}
    </div>
  )
}

export default TaskTabComments