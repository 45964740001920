import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function MatintaUserLogin() {
    return (
      <main>
        <div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img width="100%" src="https://media.gettyimages.com/id/1383550844/pt/foto/smiling-portrait-of-a-beautiful-woman-standing-in-office.jpg?s=2048x2048&w=gi&k=20&c=pD7q_N7KUvSlKFhcKtGOdOFOj6Xh4L_31j-zqdNiQL0="/>
              <div className="container">
                <div className="carousel-caption text-start">
                  <h1 style={{"display": "inline-block", "fontSize": "100px", "color": "#6B696A"}}>Matinta</h1><br/>
                  <p style={{"color": "#6B696A", "backgroundColor": "rgba(51, 170, 51, .4)", "display": "inline-block"}}>Acesso o seu Dashboard</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row featurette">
            <div className="col-md-12 text-center">
              <LinkContainer to="/tasks">
                <Button size="lg" className="featurette-heading fw-normal lh-1 btn-large">Login</Button>
              </LinkContainer>
            </div>
          </div>
        </div>
      </main>
    );
}

export default MatintaUserLogin;
